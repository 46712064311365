import { Container, Col, Row, NavDropdown, Nav, Navbar } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import './Header.scss';
import MainNavBar from "./main_menu";
import { useEffect } from "react";
import configUrl from "../config";


function Header() {

    useEffect(() => {
        // content
        var vid = document.getElementById("myVideo");
        vid.playbackRate = 0.8;

    }, []);
    return (
        <>
            <MainNavBar />
            <div className="banner">
                <div className="shape-1"></div>
                <Container>
                    <Row>
                        <Col md={6} lg={6} sm={12} xs={12}>
                            <h3>DTS AI and RPA development team provides following.</h3>

                            <h5>mage identify and detection by Yolo2.

Image detection to classify binary value for trading market by CNN.

RNN for trading market prediction. (Stock exchange and cryptocurrency)</h5>
                            <div className="case-study-btn mt-3">
                                <NavLink className="btn_7sft" to="/aboutus">
                                    Read More
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={6} lg={6} sm={12} xs={6}></Col>
                    </Row>
                    <div className="hero_video">
                        <video id="myVideo" width="100%" height="100%" className="video_class" loop muted autoPlay>
                            <source src={configUrl.ImgBaseUrl+"/images/video.mp4"} type="video/mp4" />
                        </video>
                    </div>

                </Container>
            </div>
        </>
    );
}

export default Header;