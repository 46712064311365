import React from "react";
import Footer from "../components/Footer";
import MainNavBar from "../components/main_menu";
import { Container, Col, Row, Form } from "react-bootstrap";
import { useState, useEffect } from 'react';
import '../pages_scss/Career.scss'
import Slider from "react-slick";
import configUrl from "../config";
import ScrollAnimation from 'react-animate-on-scroll';




function Career() {

  
  useEffect(() => {

    window.scrollTo(0, 0)

  }, [])

  const properties = {
    duration: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
  };

 
  return (
    <>
      <MainNavBar />

      <div className="hero_video_other">
        <video id="myVideo" width="100%" style={{height:"300px"}} className="video_class_others" loop muted autoPlay>
          <source src={configUrl.ImgBaseUrl + "/images/video-CEO.mp4"} type="video/mp4" />
        </video>
      </div>

      {/* CONTENT */}

      <Container>
        <Row className="d-flex justify-content-center mt-5">
          <Col md={8}>
          <Slider className="slide_img" {...properties}>
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/1.png"} />
                 </Col >
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/2.png"} />
                 </Col >
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/3.jpg"} />
                 </Col >
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/4.jpg"} />
                 </Col >
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/5.jpg"} />
                 </Col >
                 <Col md={12} >
                 <img src={configUrl.ImgBaseUrl+"/images/sneakpeeks/6.jpg"} />
                 </Col >
                </Slider>
          </Col>

        </Row>
                
        <Container>
        <p style={{ color: "red" }}>L1 & L2 Blockchain Design and Implementation
                            </p>
                <Row className="mt-3">
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                      
                        <div>
                           
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/ai1.png"} />
                            

                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                           
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/ai2.jpg"} />

                        </div>
                       

                    </Col>

                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div className="flex">
                            <img width="600px" height="400px" src={configUrl.ImgBaseUrl + "/images/ai3.jpg"} />
                        </div>

                    </Col>

                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>Voting Blockchain
                            </p>
                            <div className="flex">
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/ai4.jpg"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>Smart Contract
                            </p>
                            <div className="flex">
                            <img width="400px" height="300px" src={configUrl.ImgBaseUrl + "/images/ai5.jpg"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>NFT Token Design (Market Place and Implementation)
                            </p>
                            <div className="flex">
                            <img width="600px" height="700px" src={configUrl.ImgBaseUrl + "/images/ai6.png"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>NFT Token 
                            </p>
                            <div className="flex">
                            <img width="800px" height="500px" src={configUrl.ImgBaseUrl + "/images/ai7.jpg"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>NFT Token 
                            </p>
                            <div className="flex">
                            <img width="900px" height="500px" src={configUrl.ImgBaseUrl + "/images/ai8.jpg"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>Day wise Prediction DNN
                            </p>
                            <div className="flex">
                            <img width="600px" height="400px" src={configUrl.ImgBaseUrl + "/images/ai9.jpg"} />
                            </div>
                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>Weekly Prediction DNN 
                            </p>
                            <div className="flex">
                            <img width="600px" height="400px" src={configUrl.ImgBaseUrl + "/images/ai10.jpg"} />
                            </div>
                        </div>

                    </Col>

                  
                </Row>
            </Container>

      </Container>

      {/* CONTENT */}

    
      <Footer />
    </>
  );
}

export default Career;