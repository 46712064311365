import React from "react";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import '../pages_scss/AboutUs.scss';
import MainNavBar from "../components/main_menu";
import { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import configUrl from "../config";

function Ceo() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainNavBar />
      <div className="hero_video_other">
        <video id="myVideo" width="100%" height="50%" className="video_class_others" loop muted autoPlay>
          <source src={configUrl.ImgBaseUrl + "/images/video-CEO.mp4"} type="video/mp4" />
        </video>
      </div>

      <Container>
        <Row className="mt-3">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <ScrollAnimation animateIn='flipInX' >
                <h2 class="title_7sft">Profile of CEO</h2>
                <h4>
                  Prof. Hiro Takahashi, Ph.D.
                </h4>
              </ScrollAnimation>
            </div>
</Col>
    <Col md={8} lg={8} xs={8} sm={8} className="mt-5">
            <h5>
              ACHIEVED RESEARCH AND INDYSTRY ACTIVIITIES
            </h5>
            <p>
              Prof. Dr. Hiro Takahashi is the innovation professional researcher. He holds PhD at Engineering from the Tokyo Institute of 
              Technology and MOT from Tokyo University science. His PhD thesis is based on Autonomous Decentralized distributed system 
              architecture with multiple nodes having either only L3 cache or both L3 and L4 cache. He innovated DTS (Data Transmission System) 
              architecture in present computer system. Dr. Hiro Takahashi was appointed Visiting Professor in NUST SEECS at 2011, Jinnah University
               of Women and Prof. / Director of ORIC at Greenwich University at 2015 and 2016. From JAN 2017, He was appointed Associate Professor
                at Computer Science department at DHA SUFFA University. He designed new computer architecture and radial BUS model in 
                California USA. He had work at Super Computer domain more than ten years in USA Oregon and Silicon Valley in California.
            </p>
         </Col> 

         <Col md={4} lg={4} xs={4} sm={4} className="mt-5">
         <img style={{borderRadius:"200px",boxShadow:"rgba(0, 0, 0, 0.25) 30px 54px 55px, rgba(0, 0, 0, 0.5) 10px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} width="300px" height="300px" src={configUrl.ImgBaseUrl + "/images/ceo.png"} />
         </Col> 


            <Col md={12} lg={12} xs={12} sm={12} className="mt-5"> <p>
            His recently research is Ontology Categorization. This research is key technology for AI technology at new service industry. 
            In the business is the public transportation service industry and need to predict passengers want. To provide highest quality of 
            service, understanding passenger preference is main factor. His Ontology technology can classify each passenger requirement and 
            reflect to enhance the service in the cabin in the aircraft and train. New approach he did recently, he proposes hybrid contents 
            identify algorithm using Ontology and Machine Learning. This algorithm enhances the accuracy of inside of contents by text using 
            Ontology and judge by image data on the website. He publish the book “Ontology Categorization and its implementation” in 2017 from 
            ACM publisher USA. Another research topics is PAI which is Personal Artificial Intelligence. His concept shows individual persons 
            Intelligence and knowledge are very unique compare others. Therefore human Artificial Intelligence should have individual manner. 
            PAI will be able to implement any devices, materials, automobile and inside residence to help them life. His goal of research is 
            making Personal Artificial Intelligence Smart City near future.　DTS is the technology to enhance the total computation power in 
            present Von Neumann Computer Architecture. He innovated block chain technology using DTS enhancing technique to obtain more hash 
            rate for crypto currency mining. He is contributing Japan/Europe new blockchain project as technical project board and publish 
            some papers on IEEE reviewed paper on 2018. From JAN 2019, He become the chairman of Pakistan Blockchain academic council. 
            From 2019, he will contribute to design AI training program by the order from Pakistan New Government.
            </p>
            <h5>
            Research topics:
            </h5>
            <p>
            Data Transmission System (DTS), Autonomous Decentralized Multi Layered Cache System. Original Blockchain, Voting Blockchain, 
            Blockchain hash rate optimization, AI. (Image detection), data analysis, Ontology categorization, Decentralized Secure sharing.
            </p>
            <h5>
            Blockchain Projects:
            </h5>
            <ul><li>Sky-Hash: The 3rd Gen. Mining concept technical adviser.</li></ul>
            <ul><li>CROSS Autonomous Decentralized Network exchange services and its decentralized Blockchain wallet.</li></ul>
            <ul><li>AI Voting Blockchain design and its implementation.</li></ul>
            <ul><li>AI image detection and traceability Blockchain development</li></ul>
            <ul><li>Block Chain Academic Council (BCAC) was established at NOV 2018 in Japan under supervised by Prof. Dr.Hiro Takahashi. 
              BCAC will have subsidiary organization in Pakistan from JAN 2019. BCAC will encourage Blockchain and Artificial Intelligence 
              engineers and teaching faculty member.</li></ul>
<p>
He was selected the 1st generation of chairman of BlockChain Academic Council from 2018.
</p>
<h5>
Overseas business contribution and Pakistan federal government Board of Investment council.
</h5>
<p>
Prof. Dr. Hiro Takahashi had been contributing Japan/Pakistan business development using his own technologies and connection of industry. 
He was appointed federal government Board of Investment council at 2013 and Pakistan Japan Business council from 2018.
</p></Col>
           
        </Row>
        <Row className="mt-3">
          <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
            <div>
              <p>Dr. Hiro Takahashi also contributes toward energy industry and agriculture system development in Asian counties now a days. 
              He established DTS Incat1996 and opened local company in 2007 in Karachi. Dr. Hiro Takahashi has been investing to Pakistan since 
              2001 over 10Million USD. At energy sector, he design Magnesium battery implementation design and its control technology (RAIC) 
              and he innovated patent pending technology ADETS (Autonomous Decentralized Energy Transmission System) for natural energy 
              generation unit such as solar panel, Wind power and primary battery as Magnesium battery to facilitate into Smart Grid energy 
              power environment. Dr. Hiro Takahashi established Tokyo Multimedia at Karachi Pakistan to educate young Pakistani engineers and 
              designers. Dr. Hiro Takahashi has been appointed as Honorary Investment Counselor for Board of Investment, Prime Minister 
              Secretariat of Pakistan since October 2012 for developing import/export industrial business relationships between Pakistan and Japan.
               Some of his projects in Pakistan are water filter unit, Magnesium battery factory in Punjab area (energy sector), 
               Halaal food promotion and Textile export arrangement. From 29th May 2014, Nippon Halaal Foundation was established in Tokyo. 
               This organization are managed by Dr. Hiro Takahashi and Mr.Naeem Khan as CEO of Halaal Foundation in Pakistan. 
               Nippon Hallal Foundation provides education of Halaal and Haram/Najis definition and promote the understanding Shariah’s law in 
               Japanese people. Nippon Halaal Foundation gives the halaal certification process lecture and provides halaal certification in Japan.
                At education sector, Dr. Hiro Takahashi is also appointed as a Visiting Professor in NUST SEECS for computer science2012 at 
                MOT (management of technology) and Visiting Professor at Jinnah University of Women from JAN 2014 at Computer Science department. 
                From Jan 2015. Dr. Hiro Takahashi was appointed Prof. /Director of ORIC at Greenwich University. From August of 2015, 
                Dr. Hiro Takahashi was also appointed the Academia Head at Mauritius Branch Campus. He contributes Mauritius local University, 
                Institute to develop new education model which is called “Education 4.0”using his professional research “Ontology knowledge 
                classify”. At present his research area is aircraft maintenance Wisdom by Ontology and mission critical operation staff 
                health care and their emotion prediction by Ontology rule engine. From OCT 2019, He was selected Computer science at 
                Emaan Institute of Management & Science.
              </p>
        
            </div>

          </Col>
          <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
            <div>
            <ul><li>Ph.D. of Computer Science from Tokyo Institute of Technology</li></ul>
            <ul><li>MOT of Computer Science from Tokyo University of Science</li></ul>
            <ul><li>Visiting Researcher at University of Tokyo (Human environment department)</li></ul>
            <ul><li>Visiting Professor in NUST SEECS (Islamabad)</li></ul>
            <ul><li>Professor at jinnah University for Women (Karachi)</li></ul>

            <ul><li>Professor, Director of ORIC at Greenwich University (Karachi)</li></ul>
            <ul><li>Assoc. Professor at DHA Suffa University (Karachi)</li></ul>
            <ul><li>Assoc. Professor at Preston University (Karachi)</li></ul>
            <ul><li>Dean, Computer Science at Emaan Institute of Management and Science(Karachi)</li></ul>
            <ul><li>Honorary investment counselor Board of Investment, Prime Minister’s Secretariat of Pakistan</li></ul>

            <ul><li>Environment planner / DBSJ member/IEEE Senior member/ IEICE member / IPSJ Senior member /HL7 member</li></ul>
            <br>
            </br>
<h5>
Research area:
</h5>

<p>
Data Transmission System (DTS), Autonomous Decentralized Multi Layered Cache System. Blockchain, Voting Blockchain, Blockchain 
hash rate optimization, AI (Market Prediction), data analysis, Ontology categorization, Decentralized Secure sharing.
</p>
            </div>


          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Ceo;