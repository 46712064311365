import { Container, Col, Row, NavDropdown, Nav, Navbar } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import './Header.scss';
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import ProgressBar from "react-scroll-progress-bar";
import configUrl from "../config";
import {FaAngleDown} from "react-icons/fa"

function MainNavBar() {

    function openAITrading() {
        window.open('http://3.134.5.238:8083/dts_ai/login.php')
      }
      function openMiningPortal() {
        window.open('http://3.20.158.33/DTS-Optimizing-portal/')
      }
      function openETCPool() {
        window.open('http://18.224.148.36:81/#/')
      }
    
    const [menuOpen, setmenuOpen] = useState(false);
    const openMenu = () => {
        setmenuOpen(true)
    }
    return (
        <>
            <Container fluid className="header">
                <ProgressBar bgcolor="#9f3b35" />
                <Container>
                    <Row>
                        <Col md={2} lg={2} xl={2} sm={6} xm={6}>
                            <img src={`${configUrl.ImgBaseUrl}/images/logo.png`} className="logo" onClick={openMenu} />
                        </Col>
                        <Col md={10} lg={10} xl={10} sm={6} xm={6} className="d-flex align-items-center header_menu_7sft">

                            <div className="desktop_menu">
                                <NavLink style={{margin:"20px"}} className="nav-link main_menu mr-3" to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
                               
                                <NavLink style={{margin:"20px"}}  className="nav-link main_menu mr-3" to={`${process.env.PUBLIC_URL}/aboutus`}>About Us</NavLink>
                                <NavLink style={{margin:"20px"}}  className="nav-link main_menu main_services" to={`${process.env.PUBLIC_URL}/blockchain`}>BlockChain
                                    <FaAngleDown className="chevron_icon" />
                                    <ul  className="sub_menu_7sft">
                                        <NavLink style={{margin:"20px"}}   className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/careers`}>Artificial Intelligence Services</NavLink>
                                        
                                    </ul>
                                </NavLink>
                                <NavLink style={{margin:"20px"}}  className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/publica`}>Publications
                                    </NavLink>
                                <NavLink style={{margin:"20px"}}  className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/contactus`}>Contact Us</NavLink>
                                <NavLink style={{margin:"20px"}}  className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/ceo`}>CEO</NavLink>

                                <a style={{margin:"20px",color:"white"}}  className="nav-link main_menu main_services" to={`${process.env.PUBLIC_URL}#`}>DTS Links
                                    <FaAngleDown className="chevron_icon" />
                                    <ul  className="sub_menu_7sft">
                                        <NavLink style={{margin:"20px"}} target="_blank"  className="nav-link main_menu" onClick={openAITrading}>DTS AI Trading</NavLink>
                                        <NavLink style={{margin:"20px"}}   className="nav-link main_menu" onClick={openMiningPortal} >DTS Mining Portal</NavLink>
                                        <NavLink style={{margin:"20px"}}   className="nav-link main_menu" onClick={openETCPool}>ETC Pool</NavLink>
                                        
                                    </ul>
                                </a>

                            
                            </div>


                            {/* mobile menu */}
                            <div className="mobile_menu">
                                <Menu isOpen={menuOpen}>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/`}>Home</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/aboutus`}>About Us</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/blockchain`}>BlockChain</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/publica`}>Publications</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/contactus`}>Contact Us</NavLink>
                                    <NavLink className="nav-link main_menu" to={`${process.env.PUBLIC_URL}/ceo`}>CEO</NavLink>
                                    
                                </Menu>
                            </div>

                        </Col>
                    </Row>


                </Container>
            </Container>
        </>
    )
}

export default MainNavBar