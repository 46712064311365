import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';
import { IoCallSharp } from 'react-icons/io5';
import '../pages_scss/AboutUs.scss';
import MainNavBar from "../components/main_menu";
import Counter from "../components/Counter";
import { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import configUrl from "../config";

function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainNavBar />
      <div className="hero_video_other">
                        <video id="myVideo" width="100%" height="50%" className="video_class_others" loop muted autoPlay>
                            <source src={configUrl.ImgBaseUrl+"/images/about.mp4"} type="video/mp4" />
                        </video>
                    </div>

      <Container>
        <Row className="mt-5">
          <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
            <div className="about_content_7sft">
              <div className="section_title_7sft">
                <div class="section_title_7sft">
                  <ScrollAnimation animateIn='flipInX'>
                    <h3 class="sub_title_7sft">Who We Are</h3>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn='flipInX' >
                    <h2 class="title_7sft">About DTS</h2>
                  </ScrollAnimation>
                </div>
                <p>
                DTS Inc. which stands for Data Transmission System was established in 1996 by Dr. Takahashi. Our areas of expertise include products and solutions in information technology and computer science domain. Our products and solutions cover caching techniques, customer relationship management, business intelligence and security solutions domains. Recently we have started working on Semantic Web technology which is going to be utilized heavily in incoming years.

Platinum Cache is a caching system that helps reduce the storage bottlenecks issues that may reduce the overall performance of a system. Act Cloud is an advanced customer relationship management (CRM) system that provides your organization with features to enhance your customer interactions. DTS Business Intelligence (BI) product is for clients who want quick information and reports regarding Arab and Asian nations. DTS BI product includes dashboards and reports for users for quick decision making.

Semantic Web is a new domain in which we are currently working. We can provide different semantic web based solutions including Semantic Based Web Crawling, Semantic Based Mining of User Interests Patterns, and Semantic Based Ontological Forensic Model etc. DTS Semantic Web Crawling emphasizes on semantic meanings in categorization of the search results. It groups search results using different techniques however the techniques only utilize the basic semantic meaning and different senses of a word.

Semantic based Mining of User Interests Patterns solution can try to find out about the interests of users from social networking sites like Facebook and Twitter using semantic techniques. In contrast to conventional approaches in text categorization, we propose to directly use ontological knowledge for text categorization. The novelty of our method is that it does not rely on the training of a categorizer, making a training set unnecessary, and directly leveraging the knowledge from the ontology for text categorization. Thus without learning the distinguishing features of each category from documents in the training set, we are able to extract specific knowledge about the interesting domain or category from the ontology and use it for categorizing of tweets and users comments.

DTS Semantic Based Ontological Forensic Model is yet another semantic web solution. The solution can help in patent protection and tracking unfair transactions in a company or on Internet. Money laundering is an illegal activity carried out by individuals which occurs outside of the normal range of economic and financial systems. In terms of global usability, electronic money is most suitable for money laundering schemes. Therefore, there is always difficult to differentiate between legitimate and fraud transactions. We are working on this particular domain in which we have combined OWL ontology components to construct some of the money laundering/patent infringe/fraudulent transaction schemes which would help us to detect and decompose suspected financial schemes and recognize any of the misuse and illegal act. These ontological constructs are integrated with financial transaction specific languages to provide forensic analysis.

We also provide hardware based solutions especially in the storage domain. DTS M-Cell hard drive contains built in cache inside the drive for giving a high I/O performance. We also provide RAID solutions for different servers.

In addition to the above solutions we also provide consultancy services especially overseas market research services. Tough economic situations, the fact that the world is becoming a global village and other factors have contributed towards exploring the overseas markets. Japanese market saturation, limits of market expansion due to a decrease in Japanese population, potential market expansion chances existing in Asian and Arab markets are some other reasons forcing Japanese business community to look overseas for market expansion. DTS has experience in overseas markets research. DTS has many resources that are valuable for overseas marketing like trained call center agents and a good presence on web.

GlocalizationBiz is also one of our products. GlocalizationBiz is a website where you can market your products. On this platform there are products available in many categories. Through this platform your products can reach a large audience in many parts of the world. Small and mid size companies can take advantage from this platform for the promotion of their products. In short GlocalizationBiz is a platform through which you can expand your business horizon around the globe.
                 </p>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
      <Footer />
    </>
  );
}

export default AboutUs;