import React, { Component } from "react";
import './App.scss';
import './pages_scss/mediaQuery.scss'
import { Routes, Route,HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Publica from "./pages/Publica";
import Ceo from "./pages/Ceo";
import BlockChain from "./pages/BlockChain";
import Publication from "./pages/Publication";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import { useEffect, useState } from "react";
import { Rings } from 'react-loader-spinner'
import 'react-pagination-bar/dist/index.css'
import Career from "./pages/Careers";


function App() {
  const [loaderTime, setloaderTime] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0)
    
    setTimeout(() => {
      setloaderTime(false);
   }, 4000);


    console.log(process.env.PUBLIC_URL)
  }, [])

  return (

    <>
      {loaderTime ? (<div className="loader">
        <Rings color="#9f3b35" />
      </div>) : (
        <>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<Home />} />

            <Route path={`${process.env.PUBLIC_URL}/aboutus`} element={<AboutUs />} />
         
            <Route path={`${process.env.PUBLIC_URL}/contactus`} element={<ContactUs />} />
            <Route path={`${process.env.PUBLIC_URL}/ceo`} element={<Ceo />} />
            <Route path={`${process.env.PUBLIC_URL}/careers`} element={<Career />} />

            <Route path={`${process.env.PUBLIC_URL}/blockchain`} element={<BlockChain />} />
            <Route path={`${process.env.PUBLIC_URL}/publication`} element={<Publication />} />
            <Route path={`${process.env.PUBLIC_URL}/publica`} element={<Publica />} />
          </Routes>

          <ToastContainer position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            color=""
            pauseOnHover />


        </>)}

    </>
  );
}

export default App;
