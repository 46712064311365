import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../pages_scss/Home.scss';
import { Container, Row, Col } from "react-bootstrap";
import { BiSupport } from 'react-icons/bi';
import { MdManageAccounts } from 'react-icons/md';
import { MdMiscellaneousServices } from 'react-icons/md';
import { FaCloud } from 'react-icons/fa'
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import '../pages_scss/Testimonial.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import web_services from "../web_services/web_services";

import configUrl from "../config";


function Home() {
  const [loaderTime, setloaderTime] = useState(true);
  const [blogData, setblogData] = useState([]);
  const [employeesData, setEmp] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setloaderTime(false);
    }, 4000);

    web_services.getBlogsForHome().then((res) => {
      setblogData(res.data.blogs ?res.data.blogs:[]);
    })

    web_services.getEmployees().then((res) => {
      setEmp(res.data.employees ? res.data.employees : []);
  
    })
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
        }
      }
    ]
  };


  return (
    <>

      <>
        <Header />
        <div className="service_section">
          <Container>
            <div className="service_wraper">
              <Row className="d-flex justify-content-center">
                <Col md={3} lg={3} xs={12} sm={12}>
                  <NavLink className="navlnk" to="/consulting">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <BiSupport />
                      </div>
                      <div class="service-content">
                        <p>
DTS Inc. which stands for Data Transmission System was established in 1996 by Dr. Takahashi. Our areas of expertise include products and solutions in information technology and computer science domain. Our products and solutions cover caching techniques, customer relationship management, business intelligence and security solutions domains. Recently we have started working on Semantic Web technology which is going to be utilized heavily in incoming years.
                      </p></div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={3} lg={3} xs={12} sm={12}>
                  <NavLink className="navlnk" to="/managed">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <MdManageAccounts />
                      </div>
                      <div class="service-content">
                        
                        <p>Semantic Web is a new domain in which we are currently working. We can provide different semantic web based solutions including Semantic Based Web Crawling, Semantic Based Mining of User Interests Patterns, and Semantic Based Ontological Forensic Model etc. DTS Semantic Web Crawling emphasizes on semantic meanings in categorization of the search results. It groups search results using different techniques however the techniques only utilize the basic semantic meaning and different senses of a word.

</p>
                      </div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={3} lg={3} xs={12} sm={12}>
                  <NavLink className="navlnk" to="/softwaredev">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <MdMiscellaneousServices />
                      </div>
                      <div class="service-content">
                        <p>DTS Semantic Based Ontological Forensic Model is yet another semantic web solution. The solution can help in patent protection and tracking unfair transactions in a company or on Internet. Money laundering is an illegal activity carried out by individuals which occurs outside of the normal range of economic and financial systems. In terms of global usability, electronic money is most suitable for money laundering schemes. Therefore, there is always difficult to differentiate between legitimate and fraud transactions. 
</p>                    </div>
                    </div>
                  </NavLink>
                </Col>

                <Col md={3} lg={3} xs={12} sm={12}>
                  <NavLink className="navlnk" to="/cloud">
                    <div className="service-item text-center">
                      <div className="shape-1"></div>
                      <div className="shape-2">
                        <img src={`${configUrl.ImgBaseUrl}/images/s-shape-2.png`} alt="shape2" />
                      </div>
                      <div class="service_icon">
                        <FaCloud />
                      </div>
                      <div class="service-content">
                        
                        <p>In addition to the above solutions we also provide consultancy services especially overseas market research services. Tough economic situations, the fact that the world is becoming a global village and other factors have contributed towards exploring the overseas markets. Japanese market saturation, limits of market expansion due to a decrease in Japanese population, potential market expansion. </p>

                     </div>
                    </div>
                  </NavLink>
                </Col>
              </Row>
            </div>

            <Row className="mt-5">
              <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                <div className="about_content_7sft">
                  <div className="section_title_7sft">
                    <div class="section_title_7sft">
                      <ScrollAnimation animateIn='flipInX'>
                        <h3 class="sub_title_7sft">Who We Are</h3>
                      </ScrollAnimation>

                      <ScrollAnimation animateIn='flipInX' >
                      <p>DTS Inc Japan has been working since 1996 providing services in the field of information technology and computer science. Our vast experience in multiple domains of information technology and computer science can be seen from the vast range of products and solutions that we provide to our customers.

Our products and solutions cover caching techniques, customer relationship management, business intelligence, security solutions domains. Not only this we also provide hardware based solutions especially in the storage domain. DTS M-Cell hard drive contains built in RAM inside the drive for giving a high I/O performance.

DTS Inc has offices in Tokyo (Japan), San Jose (USA) and Karachi (Pakistan). Our team of seasoned professionals is ready to consult with you to develop a DTS solution that meets your unique requirements.
                     </p>
                      </ScrollAnimation>
                    </div>
                 
                  </div>
                </div>
              </Col>
            </Row>
          </Container>


        </div>

        <div className="section features_section_7sft">
          <div className="shap1_7sft">
            <img src={`${configUrl.ImgBaseUrl}/images/hi.gif`} alt="" />
          </div>

          <Container>
            <div className="features_wrap_7sft">
              <Row>
                <Col xl={5} md={5} xs={12} sm={12} >
                  
                </Col>
                <Col xl={7} md={7} xs={12} sm={7} >
                  <div className="features_content_wrap_7sft">
                    <div class="section_title_7sft">
                      <ScrollAnimation animateIn='flipInX'>
                        <h3 class="sub_title_7sft">WHY CHOOSE US</h3>
                      </ScrollAnimation>
                      <ScrollAnimation animateIn='flipInX' >
                        <h2 class="title_7sft">What Makes Us Stand Out?</h2>
                      </ScrollAnimation>
                    </div>
                  </div>
                  <div className="features_content_7sft">
                    <ul className="feture_ul_7sft">
                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-3.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">Technology Integration</h5>
                          <p>Our technology consultants are equipped with the right skillset to help you integrate turnkey technology solutions.</p>
                        </div>
                      </li>

                      <li className="features_item_7sft">
                        <div className="features_icon_7sf">
                          <img src={`${configUrl.ImgBaseUrl}/icons/features-4.png`} alt='' />
                        </div>
                        <div className="features_text_7sft">
                          <h5 class="title_7sft">Round The Clock Support</h5>
                          <p>We are just a call away to rescue your IT process. Our support service is available 24/7.</p>
                        </div>
                      </li>
                    </ul>
                  </div>


                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Footer />
      </>

    </>
  );
}

export default Home;