import React from "react";
import MainNavBar from "../components/main_menu";
import Footer from "../components/Footer";
import { Container, Col, Row, Form, Alert } from "react-bootstrap";
import { useState, useEffect } from 'react';
import '../pages_scss/ContactUs.scss';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import web_services from "../web_services/web_services";
import ScrollAnimation from 'react-animate-on-scroll';
import { toast } from "react-toastify";
import configUrl from "../config";
import {GrRefresh} from 'react-icons/gr';

function ContactUs() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState(""); 
  const [Subject, setSubject] = useState("");
  const [Phone, setPhone] = useState("");
  const [Des, setDes] = useState("");
  const [Capt, setCapt] = useState("");
  const [SubCapt, setSubCapt] = useState("");

  const [nameError, setNameErr] = useState("");
  const [emailError, setEmailErr] = useState("");
  const [subjectError, setSubjectErr] = useState("");
  const [phoneError, setPhoneErr] = useState("");
  const [DesError, setDesErr] = useState("");

  const [CaptchaError, setCaptchaErr] = useState("");


  useEffect(() => {
    window.scrollTo(0, 0);
    generateString(5)
  }, [])

  const validate = () => {

    let NameValid =false;
    let EmailValid=false;
    let SubjectValid =false;
    let PhoneValid = false;
    let DesValid = false;
    let CaptchaValid = false;

    if (Name == "" || Name.trim() == "") {
      setNameErr("Please Enter a Name");
      NameValid = false;
    }
    else {
        setNameErr("");
        NameValid = true;
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (Email == "" || Email.trim() == "") {
      setEmailErr("Please Enter an Email");
      EmailValid = false;
    }
    else if(!pattern.test(Email)) {
      setEmailErr("Invalid Email");
      EmailValid = false;
    }
    else {
      setEmailErr("");
      EmailValid = true;
    }

    if (Subject == "" || Subject.trim() == "") {
      setSubjectErr("Please Enter a Subject");
      SubjectValid = false;
    }
    else {
      setSubjectErr("");
      SubjectValid = true;
    }

    if (Phone == "" || Phone.trim() == "" || Phone.length != "11") {
      setPhoneErr("Please Enter a Valid Number");
      PhoneValid = false;
    }
    else {
      setPhoneErr("");
      PhoneValid = true;
    }

    if (Des == "" || Des.trim() == "") {
      setDesErr("Please Enter a Message");
      DesValid = false;
    }
    else {
      setDesErr("");
      DesValid = true;
    }

    if (Capt === SubCapt) {
      setCaptchaErr("");
      CaptchaValid = true;
    }
    else {
      setCaptchaErr("Invalid Captcha");
      CaptchaValid = false;
    }

    if (NameValid && EmailValid && SubjectValid && PhoneValid && DesValid && CaptchaValid) {
      return true;
    }
    else {
        return false;
    }
}
  const submitContact = (e) => {
    e.preventDefault();
    let isvalidate = validate();
    if (isvalidate) {
    // if (Capt === SubCapt) {
      let data = {
        name: Name,
        email: Email,
        phone: Phone,
        subject: Subject,
        message: Des
      }
      web_services.AddContact(data).then((res) => {
        console.log(res);
        toast.info("Message Sent!");
        setName("");
        setEmail("");
        setSubject("");
        setPhone("");
        setDes("");
        setSubCapt("");
        generateString(5)
      });
    // } else {
    //   toast.error("Captcha Failed!");
    // }
  }

  }

  const characters ='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#@';
  function generateString(length) 
  {
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      setCapt(result);
   }

   const onPaste = (e) => {
    e.preventDefault();
  }

const refreshCaptcha = () =>{
  generateString(5)
}

  return (
    <>
      <MainNavBar />

      <div className="hero_video_other">
                        <video id="myVideo" width="100%" height="50%" className="video_class_others" loop muted autoPlay>
                            <source src={configUrl.ImgBaseUrl+"/images/contact.mp4"} type="video/mp4" />
                        </video>
                    </div>
      <div className="contact_info_section_7sft section_margin">
        <Container>
          <Row>
            <Col md={6} lg={6} xl={6} sm={12} xs={12}>
              <div className="single_contact_info_7sft">
                <div className="info_icon_7sft">
                  <FiPhoneCall />
                </div>
                <div className="info_contact_7sft">
                  <h5 className="title">Phone</h5>
                  <p>+81-3-6459-4611</p>
                  <p>+92-21-34153105</p>
                  <p>+92-21-34143104</p>
                </div>
              </div>
            </Col>

            <Col md={6} lg={6} xl={6} sm={12} xs={12}>
              <div className="single_contact_info_7sft">
                <div className="info_icon_7sft">
                  <HiOutlineLocationMarker />
                </div>
                <div className="info_contact_7sft">
                  <h5 className="title">Location</h5>
                  {/* <p>Shaheen Towers, P.E.C.H.S. Block 6</p> */}
                  <p className="para_contact_7sft">824-66 Jiyuugaoka, Tsukbashi, Ibaraki 300-1266</p>
                  <p className="para_contact_7sft">111 N Market ST, 6th FL, San Jose CA. 95113, USA</p>
                  <p className="para_contact_7sft">DTS Tokyo Lab: 4-16-14 Shibaura MInato-ku, Tokyo Japan</p>
                  
                  <p className="para_contact_7sft">Mezzanine Floor, KNG Centre Plot ST-23 Block 4A Journalist Society Gulshan-e-Iqbal Karachi.</p>
                </div>
              </div>
            </Col>

          </Row>
        </Container>
      </div>


      <div className="contact_form_section section_margin">
        <Container>
          <div className="contact_wrap_7sft">
            <Row className="justify-content-center">
              <Col md={8} lg={8} sm={12} xs={12} xl={8}>
                <div className="form_title_7sft">
                  <ScrollAnimation animateIn='flipInX' >
                    <h3 className="title">
                      Get Support from our Team
                    </h3>
                  </ScrollAnimation>
                </div>

                <div className="contact_form_wrap_7sft">
                  <Row>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Control type="text" placeholder="Your Name" value={Name} onChange={(e) => setName(e.target.value)} />
                        <div style={{ color: 'red' }}>{nameError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Control type="text" placeholder="Your Email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                        <div style={{ color: 'red' }}>{emailError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Control type="text" placeholder="Your Subject" value={Subject} onChange={(e) => setSubject(e.target.value)} />
                        <div style={{ color: 'red' }}>{subjectError}</div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="single_input_7sft">
                        <Form.Control type="number" placeholder="Your Number" value={Phone} onChange={(e) => setPhone(e.target.value.slice(0, 11))} />
                        <div style={{ color: 'red' }}>{phoneError}</div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="single_input_7sft">
                        <Form.Control as="textarea" rows={5} placeholder="Write A Message" value={Des} onChange={(e) => setDes(e.target.value)} />
                        <div style={{ color: 'red' }}>{DesError}</div>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="d-flex align-items-center">

                        <div className="single_input_7sft d-flex align-items-center">
                        <div className="unselectable" style={{color:"#ffffff", backgroundColor:"red", fontSize:"20px", padding:'5px',letterSpacing: '3px'}}>{Capt}</div>
                        <GrRefresh className="icon_style" onClick={refreshCaptcha}/>
                        </div>

                        <div className="single_input_7sft" style={{ marginLeft: '15px' }}>
                          <Form.Control onPaste={onPaste} type="text" placeholder="Verify Captcha" value={SubCapt} onChange={(e) => setSubCapt(e.target.value)} />
                        </div>
                      </div>
                      <div style={{ color: 'red' }}>{CaptchaError}</div>
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
            <div className="case-study-btn text-center mt-3">
              <button className="btn_7sft" onClick={submitContact}>
                Send
              </button>
            </div>
          </div>
        </Container>
      </div>
{/* 
      <div className="map_wrap_section_7sft section_margin">
        <div className="map_wrap_7sft">
          <div className="form_title_7sft">
            <ScrollAnimation animateIn='flipInX' >
              <h3 className="title">
                Find us on Google Map
              </h3>
            </ScrollAnimation>

          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226.1101810602625!2d67.09925643338657!3d24.940145957827873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb339d083ce4501%3A0xafe20e62dc25c802!2sMeezan%20Bank!5e0!3m2!1sen!2s!4v1656053109517!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
          
            </div>
      
      
      </div> */}


      <Footer />
    </>
  );
}

export default ContactUs;