import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';
import { IoCallSharp } from 'react-icons/io5';
import '../pages_scss/AboutUs.scss';
import MainNavBar from "../components/main_menu";
import Counter from "../components/Counter";
import { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import configUrl from "../config";
import Table from 'react-bootstrap/Table'

function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainNavBar />
      <div className="hero_video_other">
        <video id="myVideo" width="100%" height="50%" className="video_class_others" loop muted autoPlay>
          <source src={configUrl.ImgBaseUrl + "/images/about.mp4"} type="video/mp4" />
        </video>
      </div>

      <Container>
<h3 className="text-center mb-5 mt-5">Journal Paper</h3>
      <Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="200">S/No</th>
      <th width="200">Author Name</th>
      <th width="200">Paper Title</th>
      <th width="200">Published Date</th>
 
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>HIRONAO Hieonao Takahashi , Uzair Lakhani, and Asif Raza.</td>
      <td>Knowledge Upload Service Using Semantic Based Categorization". Mehran University Research Journal of Engineering & Technology  Vol. 38, No. 4, 999-1008</td>
      <td>October 2019</td>
    </tr>
    
    <tr>
      <td>2</td>
      <td>Hironao Takahashi, Khalid Mahmood Malik, Mori Kinji</td>
      <td>"The impact of Japanese economy by emotion sensing communication ". Journal of Business strategy 17th  ISSN 1993-5765</td>
      <td>1- July 2015</td>
    </tr>

    <tr>
      <td>3</td>
      <td>Hironao Takahashi, Khalid Mahmood Malik, Mori Kinji</td>
      <td>Autonomous Decentralized Multi-Layer Cache System (ADMLCS) to low latency user push web services for High Availability and Timeliness&rdquo; Intech Journal of Emerging Technology ISBN 979-953-307-622-4.</td>
      <td>March 2012</td>
    </tr>

    <tr>
      <td>4</td>
      <td>Hironao Takahashi, Khalid Mahmood Malik, Mori Kinji</td>
      <td>"Autonomous L3 Cache Technology for High Responsiveness" IPSJ Transaction  Journal of Information Processing Vol.20 No.2</td>
      <td>Feb 2012</td>
    </tr>

    <tr>
      <td>5</td>
      <td>Hironao Takahashi and Mori Kinji</td>
      <td>"Web application firewall to analyze autonomous distributed in response to cyber attack Xiaodong Lu, of a local HTTP level." IEIEC Special Published 10. Pp1595-1603 Dvol J94-D No 10, October 2011 (WAF) Journal Paper </td>
      <td>October 2011</td>
    </tr>

    <tr>
      <td>6</td>
      <td>&#39640;&#27211;&#23439;&#23578; ,&#26862;&#12288;&#27427;&#21496;</td>
      <td>&#65288;&#26481;&#24037;&#22823;&#65289;IPSJ 50th Aniv. 18.&#65339;&#20844;&#21215;&#35542;&#25991;&#65341;&#33258;&#24459;&#20998;&#25955;&#12471;&#12473;&#12486;&#12512;&#12398;&#39640;&#24540;&#31572;I/O &#12494;&#12540;&#12489;&#12398;&#25361;&#25126;</td>
      <td>May 2010</td>
    </tr>

    <tr>
      <td>7</td>
      <td>&#39640;&#27211;&#23439;&#23578; , Khalid Mahmood Malik , &#26862;&#12288;&#27427;&#21496; </td>
      <td>IPSJ Special Issue: Push&#22411;Web&#12469;&#12540;&#12499;&#12473;&#12395;&#12362;&#12369;&#12427;&#39640;&#24540;&#31572;&#36890;&#20449;&#12398;&#12383;&#12417;&#12398;&#33258;&#24459;&#20998;&#25955;&#12452;&#12505;&#12531;&#12481;&#12515;&#12522;</td>
      <td>10th AUG  2010 </td>
    </tr>

    <tr>
      <td>8</td>
      <td>K. Mahmood, R. Sakamoto , X. Lu, K. Mori</td>
      <td>Autonomous Pull-Push Community Multi-Service-area Construction Technology to Achieve Service Assurance&rdquo; Journal of Convergence Information Technology.</td>
      <td>2010</td>
    </tr>

    <tr>
      <td>9</td>
      <td>K. Mahmood, X.D. Lu, Y. Horikoshi and K. Mori</td>
      <td>"Autonomous Pull-Push Community Construction Technology for High-Assurance", IEICE Trans. on INFO. & SYST., Vol.E92-D, No.10, pp.1836-1846</td>
      <td>2009</td>
    </tr>

    <tr>
      <td>10</td>
      <td>K . Mahmood, X. Lu, K. Mori</td>
      <td>Autonomous Community Construction Technology to Achieve Service Assurance in ADCS&rdquo;, IEICE Trans. on INFO. &amp; SYST., vol. E91-D, No. 9, pp. 2259-2266</td>
      <td>09/01/2008</td>
    </tr>
  </tbody>
</Table>

<h3 className="text-center mb-5 mt-5">International Conference Paper (Reviewed paper)</h3>
      <Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="200">S/No</th>
      <th width="200">Author Name</th>
      <th width="200">Paper Title</th>
      <th width="200">Published Date</th>
 
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Hironao Takahashi , Shinji Nakano , Uzair Lakhani</td>
      <td>SHA256d Hash Rate Enhancement by L3 Cache”.  IEEE  7th Conference on Consumer Electronics (GCCE) ISSN: 2378-8143</td>
      <td>13- December 2018</td>
    </tr>
    
    <tr>
      <td>2</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"Autonomous Decentralized Kernel Cache Architecture for Multi Ontology Based Information Extraction on Microsoft Windows", in IEEE ISADS2017</td>
      <td>22 - 25/03/2017</td>
    </tr>

    <tr>
      <td>3</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"Improving the Understanding between Control Tower Operator & Pilot Using Semantic Techniques", in IEEE SASWIN 2017.</td>
      <td>22 - 25/03/2017</td>
    </tr>

    <tr>
      <td>4</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"Pilot Eye Monitoring for Aircraft Safety Using Semantic Web Tools & Technologies", in IEEE GCCE 2016.</td>
      <td>11 - 15/10/2016</td>
    </tr>

    <tr>
      <td>5</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"Innovative High Quality Aircraft Maintenance by Wisdom of Semantic Database Using Historical Data of Operation Staffs", in IEEE ISMS2016 </td>
      <td>25 - 27/01/2016</td>
    </tr>

    <tr>
      <td>6</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"Cloud Based Sports Analytics Using Semantic Web Tools and Technologies", in IEEE GCCE 2015.</td>
      <td>25 - 30/01/2015</td>
    </tr>

    <tr>
      <td>7</td>
      <td>Khalid Mahmood , Hironao Takahashi, Mazen Alobaidi</td>
      <td>"A Semantic Approach for Traceability Link Recovery in Aerospace Requirements Management System", In proceedings of IEEE ISADS 2015 Taiwan.</td>
      <td>26/02/2015 </td>
    </tr>

    <tr>
      <td>8</td>
      <td>Khalid Mahmood, Hironao Takahashi,Asif Raza, Asma Qaiser, Aadil Farooqui</td>
      <td>"Semantic based Highly Accurate Autonomous Decentralized URL Classification System for Web Filtering", In proceedings of IEEE ISADS</td>
      <td>15/02/2015</td>
    </tr>

    <tr>
      <td>9</td>
      <td>Hironao Takahashi,  Khalid Mahmood, Uzair Lakhani</td>
      <td>"Autonomous Decentralized Semantic based URL Filtering System for Low Latency", In proceedings of IEEE ISADS 2015 Taiwan.</td>
      <td>10/02/2015</td>
    </tr>

    <tr>
      <td>10</td>
      <td>Dr. Hironao Takahashi</td>
      <td>"High Assurance System Engineering (HASE) for URL Filtering", in IEEE HASE 2016.</td>
      <td>09/01/2014 - 11/01/2014</td>
    </tr>

    <tr>
      <td>11</td>
      <td>Hironao Takahashi,Khalid Mahmood Malik, Rikyo Takahashi, Kinji Mori</td>
      <td>Emotion sense Ontology to avoid Human error on Autonomous
Decentralized Multi-Layered Cache System
<b>Conference name:</b> ISADS 2013, Mexico city, Mexico</td>
      <td>03/05/2013</td>
    </tr>
    
    <tr>
      <td>12</td>
      <td>Khalid Mahmood ,Hironao Takahashi , Yasukai Arakawa</td>
      <td>"Gateway Access Permission Technology for High Assurance”. IEEE   32nd International Conference on Distributed Computing Systems Workshops INSPEC Accession Number: 12911442</td>
      <td>06-August 2012</td>
    </tr>

    <tr>
      <td>13</td>
      <td>Rikyo Takahashi, Hironao Takahashi, Khalid Mahmood Malik</td>
      <td>Human environment information conference at University of Tokyo.</td>
      <td>08/30/2012</td>
    </tr>

    <tr>
      <td>14</td>
      <td>Rikyo Takahashi, Hironao Takahashi, Khalid Mahmood Malik</td>
      <td>Emotion Sensor Communication to Web Virtual Shop Service for Individual User</td>
      <td>08/01/2012</td>
    </tr>

    <tr>
      <td>15</td>
      <td>Khalid Mahmood Malik, Hironao Takahashi, Kinji Mori</td>
      <td>Gateway Access Permission Technology for High Assurance, IEEE ADSN 2012, China</td>
      <td>06/01/2012</td>
    </tr>

    <tr>
      <td>16</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>IEEE ISADS 2011 conference paper Oct 2010: Application for ADMLC system to WAF.</td>
      <td>Oct 2010</td>
    </tr>

    <tr>
      <td>17</td>
      <td>Abdul Razzaq, Ali Hur, Muddassar Masood, Khalid Latif, Hafiz Farooq Ahmad, Hironao Takahashi </td>
      <td>IEEE &ldquo;Foundation of Semantic Rule Engine T o Protect Web Application Attacks&rdquo; IEEE Proc. ISADS 2011, Tokyo, Japan.</td>
      <td>2011 </td>
    </tr>

    <tr>
      <td>18</td>
      <td>K. Mahmood, Hiro Takahashi, X.D. Lu and K. Mori</td>
      <td>Autonomous Decentralized Community Wireless Sensor Network System Architecture to Achieve High-Speed Connectivity under Dynamical Situation&quot;, IEEE Proc. of ISADS 2009, pp.297-304, Athens, Greece</td>
      <td>2011</td>
    </tr>

    <tr>
      <td>19</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>"Autonomous Short Latency System for Web Application Layer Firewall&rdquo; Proc. IEEE RTSOAA 2010 Florida USA pp. 14-16</td>
      <td>July 2010</td>
    </tr>

    <tr>
      <td>20</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>Autonomous online expansion / reduction system technology for high I/O demand service&rdquo; International Conference on Advances and Emerging Trends in Computing Technologies ICAET10 pp. 21-24</td>
      <td>June 2010</td>
    </tr>
    <tr>
      <td>21</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>Autonomous online expansion / reduction system technology for high I/O demand service&rdquo; International Conference on Advances and Emerging Trends in Computing Technologies ICAET10 pp. 21-24</td>
      <td>June 2010</td>
    </tr>
    
    <tr>
      <td>22</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>The Advantage of Block level L4 cache for NAND Flash SSD in Web Application Environment&rdquo; IEEE Web DB forum 2009</td>
      <td>Nov 2009</td>
    </tr>

    <tr>
      <td>23</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>Balanced Memory Architecture for High I/O Intensive Information Services for Autonomous Decentralized System&rdquo; IEEE ISADS</td>
      <td>March 2009</td>
    </tr>

    <tr>
      <td>24</td>
      <td>K. Mahmood, S. Niki, X.D. Lu K. Mori</td>
      <td>Autonomous Hybrid Pull-Push Context-aware Community Service Dissemination Technology to Achieve High Assurance", IEEE Proc. of ISADS 2009, pp.499-506, Athens, Greece</td>
      <td>March 2009</td>
    </tr>

    <tr>
      <td>25</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>"Usage Pattern Based Pre-fetching For Mechanical Mass Storage" IEEE HONET 2008 IEEE Paper for Sep 15 ? 18 2009 at 32nd Annual Conference on Artificial Intelligence</td>
      <td>Sep 2009</td>
    </tr>

    <tr>
      <td>26</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>IEEE Dec 2008 HASE 2008 conference in Nanjing? : Conference ( Published ) </td>
      <td>Dec 2008</td>
    </tr>

    <tr>
      <td>27</td>
      <td>K. Mahmood, S. Niki, X.D. Lu K. Mori</td>
      <td>"A Novel Ripple-based Context-cognizant Service Discovery Method in Autonomous Decentralized Community System", IEEE Proc. of the 11th High Assurance Systems Engineering Symposium, pp.405-413, Nanjing, China</td>
      <td>Dec 2008</td>
    </tr>

    <tr>
      <td>28</td>
      <td>K. Mahmood, Y. Horikoshi, S. Niki, X.D. Lu K. Mori</td>
      <td>"Progressive Ripple-Based Service Discovery for High Response Time in Autonomous Decentralized Community System", IEEE Proc. of FTDCS 2008, pp.81-87, Kunming, China</td>
      <td>Oct 2008</td>
    </tr>

    <tr>
      <td>29</td>
      <td>K. Mahmood, S. Niki, X. Lu, K. Mori</td>
      <td>Context-cognizant community construction in Autonomous Decentralized Community System to Achieve Timeliness, CSA 2008, Hobert , Australia.</td>
      <td>2008</td>
    </tr>

    <tr>
      <td>30</td>
      <td>K. Mahmood, Y. Kanamaru, X.D. Lu K. Mori</td>
      <td>"Autonomous Decentralized Community Construction Technology for High Quality Information Service", IEEE Proc. of the 28th International Conference on Distributed Computing Systems Workshops (ICDCSW), pp.563-568, Beijing, China</td>
      <td>June 2008</td>
    </tr>

    <tr>
      <td>31</td>
      <td>K. Mahmood, S. Niki, Y. Nakahara, X.D. Lu, I. Luque, K. Mori,</td>
      <td>"Autonomous Real-Time Navigation for Service Level Agreement in Distributed Information Service System", IEEE Proc. of ISADS2007, pp.231-238, Sedona, USA</td>
      <td>March 2007</td>
    </tr>

    <tr>
      <td>32</td>
      <td>Hironao Takahashi, Uzair Lakhani, Amjad Hussain.</td>
      <td>Secure sharing for Cryptocurrency Wallet in Autonomous Decentralized Multi Layered Cache System “ ISADS 2019 IEEE, Netherland</td>
      <td>8th to 10th APR 2019</td>
    </tr>

    <tr>
      <td>33</td>
      <td>Hironao Takahashi, Uzair Lakhani</td>
      <td>Multiple Layered Security Analyses Method for Cryptocurrency Exchange Servicers, IEEE international conference GCCE2019 in OOSAKA, OS-CPS session</td>
      <td>15th to 18th OCT 2019</td>
    </tr>

    <tr>
      <td>34</td>
      <td>Hironao Takahashi, Uzair Lakhani, Amjad Hussain.</td>
      <td>Observation Agent Node for Secure Sharing Data Store in Autonomous Decentralized Network, IEEE international conference GCCE2019 in OOSAKA, OS-CPS session</td>
      <td>15th to 18th OCT 2019</td>
    </tr>

    <tr>
      <td>35</td>
      <td>Hironao Takahashi, Uzair Lakhani</td>
      <td>Kernel Based Cache Effect on Blockchain Node Synchronization , IEEE international conference GCCE2020 in Kobe, OS-CPS session</td>
      <td>13th to 16th OCT 2020</td>
    </tr>

    <tr>
      <td>36</td>
      <td>Hironao Takahashi, Uzair Lakhani</td>
      <td>Voting blockchain for High Security NFT, IEEE international conference GCCE 2021 in Kyoto, OS-CPS session</td>
      <td>12th to 15th OCT 2021</td>
    </tr>

    <tr>
      <td>37</td>
      <td>Hironao Takahashi, Uzair Lakhani</td>
      <td>Sustainable NFT Blockchain Storage for high availability and security, IEEE international conference GCCE2022 in Oosaka, Japan OS-CPS session</td>
      <td>18th to 21st OCT 2022</td>
    </tr>

    <tr>
      <td>38</td>
      <td>Hironao Takahashi, Uzair Lakhani</td>
      <td>Implementation of Smart Contract on Autonomous Decentralized Voting Blockchain ISADS 2023 IEEE international conference, Universidad Panamericana, Mexico City, Mexico, Accepted Paper number: 9287</td>
      <td>March 15th to 17th 2023</td>
    </tr>
  </tbody>
</Table>
 
<h3 className="text-center mb-5 mt-5">International Conference invited Paper & Presentation</h3>
      <Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="200">S/No</th>
      <th width="200">Author Name</th>
      <th width="200">Paper Title</th>
      <th width="200">Published Date</th>
 
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Hironao Takahashi</td>
      <td>NUST invitation speech : 2010 Feb Timeliness ADS concept and its application.</td>
      <td>02/10/2010</td>
    </tr>
    
    <tr>
      <td>2</td>
      <td>Hironao Takahashi</td>
      <td>NUST invitation speech : 2009 March L4 cache node architecture.</td>
      <td>03/02/2009</td>
    </tr>

    <tr>
      <td>3</td>
      <td>Hironao Takahashi</td>
      <td>NUST invitation speech : 2008 NOV ADDTS concept and L3 cache node.</td>
      <td>2008 Nov</td>
    </tr>

    <tr>
      <td>4</td>
      <td>Hironao Takahashi</td>
      <td>HePIX Taiwan : invited paper Oct 20th 2008 </td>
      <td>Oct 2008</td>
    </tr>

    
  </tbody>
</Table>
 
<h3 className="text-center mb-5 mt-5">Domestic Paper and Conferences</h3>
      <Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="200">S/No</th>
      <th width="200">Author Name</th>
      <th width="200">Paper Title</th>
      <th width="200">Published Date</th>
 
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>"High assurance L3 cache micro SD card to SMART phone application". ADS research conference No36th in Tokyo Hosei Univ.</td>
      <td>Sep 2011</td>
    </tr>
    
    <tr>
      <td>2</td>
      <td>Hironao Takahashi, Ali M. Hu, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>"Semantic Rule Detection Approach to Web Application Firewall." IPSJ / IEICE Web DB forum 2010 NOV 2010</td>
      <td>Nov 2010</td>
    </tr>

    <tr>
      <td>3</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>2009 Feb 21st 26th Assurance conference</td>
      <td>02/21/2009</td>
    </tr>

    <tr>
      <td>4</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>July 2008 Assurance conference</td>
      <td>07/01/2008</td>
    </tr>

    <tr>
      <td>5</td>
      <td>&#39640;&#27211;&#23439;&#23578;&#65293;&#30740;&#31350;&#22577;&#21578;&#65306;</td>
      <td>&#30011;&#20687;&#38651;&#23376;&#23398;&#20250;&#30740;&#31350;&#20250; [&#25307;&#24453;&#35611;&#28436;] &#12288;&#12527;&#12452;&#12450;&#12524;&#12473;&#36890;&#20449;&#12395;&#12362;&#12369;&#12427;&#23433;&#20840;&#24615;&#12434;&#39640;&#12417;</td>
      <td>03/01/2008</td>
    </tr>

    <tr>
      <td>6</td>
      <td>K. Mahmood, S. Niki, S. Murakami, X. Lu, K. Mori</td>
      <td>Scalable Autonomous Load Balanced Topology Construction for Mobile Wireless Sensor Network&rdquo; Tech. Report of the 23rd IEICE Assurance Symposium, Tokyo, Japan</td>
      <td>11/01/2007</td>
    </tr>

    <tr>
      <td>7</td>
      <td>K. Mahmood, S. Zhong, X. Lu K. Mori</td>
      <td>Autonomous Decentralized Mobile Community System for Provision of Service Assurance to Local-majority Users, Tech. Report of the 29th Assurance Symposium, IEICE, Tokyo, Japan</td>
      <td>01/01/2010</td>
    </tr>

    <tr>
      <td>8</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>ADS research conference No28th in Hiroshima Title: Advancing perfecting technology for high I/O intensive assurance system using hybrid case reasons. </td>
      <td>-</td>
    </tr>

    <tr>
      <td>9</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>ADS research conference No29th in Tokyo Waseda Univ. Title: The minimized Write I/O latency model on ADS architecture </td>
      <td>-</td>
    </tr>

    <tr>
      <td>10</td>
      <td>Hironao Takahashi, Hafiz Farooq Ahmad, Kinji Mori</td>
      <td>ADS research conference No30th in Tokyo July 23rd Autonomous Decentralized Data Transmission System</td>
      <td>-</td>
    </tr>
  </tbody>
</Table>

<h3 className="text-center mb-5 mt-5">Awards</h3>
      <Table stripped bordered hover size="sm">
  <thead>
    <tr>
      <th width="200">S/No</th>
      <th width="200">Award</th>
      <th width="200">Date</th>
 
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Interop finalist award at 2012 GSR emotion sense service.</td>
      <td>2012</td>
    </tr>
    
    <tr>
      <td>2</td>
      <td>Interop finalist award at 2011 SWAF.</td>
      <td>2011</td>
    </tr>

    <tr>
      <td>3</td>
      <td>&#26085;&#26412;Interop &#25216;&#34899;&#12505;&#12531;&#12481;&#12515;&#12540;&#29305;&#21029;&#36062;&#12288;&#21463;&#36062; : L3 cache SSD</td>
      <td>2010 Feb</td>
    </tr>

    <tr>
      <td>4</td>
      <td>&#12388;&#12367;&#12400;&#12505;&#12531;&#12481;&#12515;&#12540;&#12288;&#20808;&#31471;&#25216;&#34899;&#20225;&#26989;&#12288;&#29305;&#21029;&#36062;&#12288;2010&#24180;2&#26376; L3&#12487;&#12540;&#12479;&#12488;&#12521;&#12531;&#12473;&#12511;&#12483</td>
      <td>2010</td>
    </tr>

    <tr>
      <td>5</td>
      <td>TiECon 2010 :&#12288;The World?s LARGEST Conference for Entrepreneurs!May 14 -15, 2010 Santa Clara Convention Center Santa Clara, CA </td>
      <td>2010</td>
    </tr>

    <tr>
      <td>6</td>
      <td>&#12477;&#12501;&#12488;&#12454;&#12456;&#12450;&#37096;&#38272;&#12288;Finalist &#21463;&#36062;&#12288;(Top 5%) </td>
      <td>-</td>
    </tr>

   
  </tbody>
</Table>

      </Container>
      <Footer />
    </>
  );
}

export default AboutUs;