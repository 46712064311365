import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import { useLocation, NavLink, Link } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';
import { IoCallSharp } from 'react-icons/io5';
import '../pages_scss/AboutUs.scss';
import MainNavBar from "../components/main_menu";
import Counter from "../components/Counter";
import { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import configUrl from "../config";

function BlockChain() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MainNavBar />
            <div className="hero_video_other">
                <video id="myVideo" width="100%" height="50%" className="video_class_others" loop muted autoPlay>
                    <source src={configUrl.ImgBaseUrl + "/images/block_chain.mp4"} type="video/mp4" />
                </video>
            </div>

            <Container>
            <Row className="mt-3">
                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div className="about_content_7sft">
                            <ScrollAnimation animateIn='flipInX' >
                                <h2 class="title_7sft">DTS Research and Development</h2>
                            </ScrollAnimation>
                        </div>
                        <br></br>
                        <p>
                            We have research and implement of blockchain technology to new Decentralized application of industry.
                            We provide following.
                        </p>
                        <p>
                            ETH Smart Contract
                        </p>
                        <p>
                            DTS Advanced BlockChain Lab made
                        </p>
                        <ul><li>Voting Layer 1 BlockChain</li></ul>
                        <ul><li>Original Smart Contract</li></ul>
                        <ul><li>Highest Secure Hash Based Storage Node with PoS</li></ul>
                        <ul><li>AI Prediction Engine for BTC trading.</li></ul>
                        <ul><li>AI Bot for BTC/USD and ETH/USD</li></ul>
                        <ul><li>NFT Marketplace and its DNN based mint image creator tool</li></ul>
                        <ul><li>Avatar with chat GPT operator.</li></ul>

                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p style={{ color: "red" }}>Publication the paper at IEEE GCCE 2018 Implementation of L3 cache into CPU miner program
                            </p>
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/bc1.png"} />

                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p style={{ color: "red" }}>Blockchain performance was enhanced by L3 cache memory
                            </p>
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/bc2.png"} />

                        </div>
                       

                    </Col>

                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                    <p className="text-center" style={{ color: "red" }}>Hash Rate was enhanced</p>
                        <div className="flex">
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/bc3.png"} />

                        </div>

                    </Col>

                    <Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div>
                            <p className="text-center" style={{ color: "red" }}>Patent of DTS L3 cache memory
                            </p>
                            <div className="flex">
                            <img width="500px" height="600px" src={configUrl.ImgBaseUrl + "/images/bc4.png"} />
                            </div>
                        </div>

                    </Col>

                    
<Col md={12} lg={12} xs={12} sm={12} className="mt-5">
                        <div>
                            <h5>DTS provides optimization of blockchain.
                            </h5>
                            <p>
                            In the case of PoW in cryptocurrency mining, our technology boost up the hash rate and reduce the electric power consumptions both.
<br/>
                            Here is a example of blockchain optimization.
                            </p>
                          <h4>Ethereum AMD VEGA56 GPU optimization result</h4>
                           
                           <p>
We examine the hash rate of Ethereum AMD VEGA56 GPU at Pakistan machine on Windows 10 in Pakistan compare original pool mining result to L2 Optimization.
</p>
<ul><li>Algorithm: Ethash.</li></ul>
<ul><li>Average Default Mining Result (HDD-Ethminer): 30.76403 Mhash/s.</li></ul>
<ul><li>Price 141.26 USD in DEC 209.</li></ul>

                        </div>
                        <div className="flex">
                            <img width="500px" height="300px" src={configUrl.ImgBaseUrl + "/images/bc5.png"} />

                        </div>
                    </Col>

                    <p style={{ color: "red" }}>Power Consumption</p>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p style={{ color: "red" }}>Default setting is 164Watt</p>
                            <img width="300px" height="100px" src={configUrl.ImgBaseUrl + "/images/bc6.png"} />

                        </div>

                    </Col>
                    <Col md={6} lg={6} xs={6} sm={6} className="mt-5">
                        <div>
                            <p style={{ color: "red" }}>DTS Optimized setting is</p>
                            <img width="300px" height="100px" src={configUrl.ImgBaseUrl + "/images/bc7.png"} />

                        </div>
                       
                        <br/>
                    </Col>
                    
                    <p style={{ color: "red" }}>Power Consumption is 102 WATT compare 164 WATT default setting. It is 62.19% power consumption from default value</p>

                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default BlockChain;